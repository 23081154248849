import React, { memo } from 'react';

import { Tooltip } from 'antd';
import messages from 'react/business/products/product.messages';
import { PRODUCT_STAGES } from 'config/constants';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

const InitiativeTooltip = props => (
  <FormattedMessageChildren {...messages[PRODUCT_STAGES.INI]}>
    { message => <Tooltip title={message} {...props} /> }
  </FormattedMessageChildren>
);

InitiativeTooltip.displayName = 'InitiativeTooltip';

export default memo(InitiativeTooltip);
