import { defineMessages } from 'react-intl';

export default defineMessages({
  CONSULT: { id: 'home.sections.about.consult.title', defaultMessage: 'Consult' },
  CONSULT_SUB: { id: 'home.sections.about.consult.sub', defaultMessage: '“Search the digital product that fits to your need!”' },
  CONSULT_HINT: { id: 'home.sections.about.consult.hint', defaultMessage: 'Find in the platform the digital product that matches your needs.' },
  CONSULT_SELECT_PLACEHOLDER: { id: 'home.sections.about.consult.select.placeholder', defaultMessage: 'Search by...' },
  CONSULT_BUTTON_INITIATIVES: { id: 'homepage.navigation.see.all.initiatives', defaultMessage: 'See all initiatives' },
  CONSULT_BUTTON_PRODUCTS: { id: 'homepage.navigation.see.all.products', defaultMessage: 'See all products' },
  CREATE: { id: 'home.sections.about.create.title', defaultMessage: 'Create' },
  CREATE_SUB: { id: 'home.sections.about.create.sub', defaultMessage: '“Highlight your digital products!”' },
  CREATE_HINT: {
    id: 'home.sections.about.create.hint',
    defaultMessage: 'Enhance the platform by publishing the information related to your digital products.',
  },
  CONTRIBUTE: { id: 'home.sections.about.contribute.title', defaultMessage: 'Contribute' },
  CONTRIBUTE_SUB: { id: 'home.sections.about.contribute.sub', defaultMessage: '“Your user experience matters!”' },
  CONTRIBUTE_HINT: {
    id: 'home.sections.about.contribute.hint',
    defaultMessage: 'Boost the continual improvement of the digital products by expressing your feedback or a suggestion.',
  },
  CONTRIBUTE_BUTTON_FEEDBACK: { id: 'home.sections.about.contribute.button.feedback', defaultMessage: 'Give your feedback' },
  CONTRIBUTE_BUTTON_SUGGESTION: { id: 'home.sections.about.contribute.button.suggestion', defaultMessage: 'Publish a suggestion' },
});
