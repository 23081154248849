import React, { memo } from 'react';
import { compose } from 'recompose';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';

import Link from 'react/generic/link/Link';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ActionCardsGrid from 'react/business/home/sections/about/actions/ActionCardsGrid';

import messages from './home-about-section.messages';
import classNames from './home-about-section.module.scss';

const externalLinkVideo = 'https://drive.google.com/file/d/1sPN77xcdJQ1lZuyCjOVKSXaCCHegNG9S/view';

const enhancer = compose(
  memo,
);

const HomeAboutSection = () => (
  <div className={classNames.container}>
    <div className={classNames.background}>
      <div className={classNames.clipBackground} />

      <div className={classNames.trianglesContainer}>
        <div className={classNames.blueTriangle} />
        <div className={classNames.yellowTriangle} />
        <div className={classNames.redTriangle} />
      </div>
    </div>

    <div className={classNames.content}>
      <h1 className={classNames.title}>
        <FormattedHTMLMessage {...messages.TITLE} />
      </h1>

      <h2 className={classNames.baseline}>
        <a
          href={externalLinkVideo}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage {...messages.SUB_TITLE} />
        </a>
      </h2>

      <Link
        to={formatRoute(ROUTES.ABOUT)}
        className={classNames.linkLearnMore}
      >
        <div className={classNames.learnMore}>
          <FormattedMessage {...messages.LEARN_MORE} />
        </div>
        <div className={classNames.questionMark}>
          ?
        </div>
      </Link>
    </div>
    <div className={classNames.gridContainer}>
      <ActionCardsGrid />
    </div>
  </div>
);

HomeAboutSection.displayName = 'HomeAboutSection';

export default enhancer(HomeAboutSection);
