import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import cn from 'classnames';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './product-bu-count.messages';
import classNames from './product-bu-count.module.scss';

const ProductBUCount = ({
  count,
  className,
  isGlobal,
  small,
}) => (
  <div className={cn(classNames.container, className)}>
    <div className={cn(classNames.label, small && classNames.small)}>
      {count} <FormattedMessage {...messages.BUSINESS_UNIT} values={{ count }} />
    </div>
    {isGlobal && (
      <Tooltip title={<FormattedMessage {...messages.GLOBAL} />}>
        <Asset name={ICON_MAP.global} className={classNames.global} />
      </Tooltip>
    )}
  </div>
);

ProductBUCount.displayName = 'ProductBUCount';

ProductBUCount.propTypes = {
  count: PropTypes.number,
  isGlobal: PropTypes.bool,
  className: PropTypes.string,
  small: PropTypes.bool,
};

ProductBUCount.defaultProps = {
  isGlobal: false,
  count: 0,
  className: null,
  small: false,
};

export default memo(ProductBUCount);
