import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import classNames from './button.module.scss';

const enhancer = compose(
  memo,
);

const Button = ({
  outline,
  className,
  overrideClassName,
  overrideClassNames,
  ...props
}) => (
  <button
    {...props}
    className={cn(
      className,
      classNames.button,
      overrideClassName || classNames.default,
      outline && (overrideClassNames || classNames).outline,
    )}
  />
);

Button.propTypes = {
  /** Button HTML5 type. */
  type: PropTypes.oneOf([
    'button',
    'reset',
    'submit',
  ]),
  /** Display border with transparent background. */
  outline: PropTypes.bool,
  /** Root element class name. */
  className: PropTypes.string,
  /** Class including the mixin. */
  overrideClassName: PropTypes.string,
  /** Class names map of modules including the mixin. */
  overrideClassNames: PropTypes.objectOf(PropTypes.string),
};

Button.defaultProps = {
  type: 'button',
  outline: false,
  className: null,
  overrideClassName: null,
  overrideClassNames: null,
};

Button.displayName = 'Button';

export default enhancer(Button);
