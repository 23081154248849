import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';

import GenericSelect from 'react/generic/select/Select';
import withSelectValueSelector from 'react/generic/select/withSelectValueSelector';
import reduxFormInputAdapter from '../reduxFormInputAdapter';
import Field from '../field/Field';

const Select = ({
  title,
  error,
  tooltip,
  specific,
  ...props
}) => (
  <Field
    title={title}
    error={error}
    tooltip={tooltip}
    specific={specific}
  >
    <GenericSelect
      {...props}
      hasError={!!error}
    />
  </Field>
);

export const selectValueShape = PropTypes.shape({
  label: PropTypes.node,
  value: PropTypes.any,
});

Select.propTypes = {
  title: PropTypes.node,
  tooltip: PropTypes.node,
  specific: PropTypes.bool,
  error: PropTypes.node,
  options: PropTypes.arrayOf(selectValueShape),
};

Select.defaultProps = {
  title: null,
  tooltip: null,
  specific: false,
  error: null,
  options: [],
};

export default Select;

const reduxFormEnhancer = compose(
  reduxFormInputAdapter,

  /** Redux-form needs the value in the onBlur handler. */
  withHandlers({
    onBlur: ({ onBlur, value }) => () => onBlur?.(value),
  }),
);

export const ReduxFormSelect = reduxFormEnhancer(Select);

export const ReduxFormSelectWithValueSelector = compose(
  reduxFormEnhancer,
  withSelectValueSelector,
)(Select);

export const withReduxFormDefaultValue = withProps(({ value }) => ({ value: value || null }));

export const SelectWithValueSelector = withSelectValueSelector(Select);
