import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    id: 'pages.form.step.choose.title',
    defaultMessage: 'What do you want to publish today?',
  },
  INITIATIVE: {
    id: 'pages.form.step.choose.initiative',
    defaultMessage: 'An initiative?',
  },
  PRODUCT: {
    id: 'pages.form.step.choose.product',
    defaultMessage: 'A product?',
  },
  INIATIVE_DESC: {
    id: 'pages.form.step.choose.initiative.desc',
    defaultMessage: 'An initiative corresponds to all the steps preceding the creation of a product.',
  },
  INIATIVE_DESC_LIST_1: {
    id: 'pages.form.step.choose.initiative.desc.list.1',
    defaultMessage: 'Business Blueprint',
  },
  INIATIVE_DESC_LIST_2: {
    id: 'pages.form.step.choose.initiative.desc.list.2',
    defaultMessage: 'Request for information',
  },
  INIATIVE_DESC_LIST_3: {
    id: 'pages.form.step.choose.initiative.desc.list.3',
    defaultMessage: 'Request for proposal',
  },
  INIATIVE_DESC_LIST_4: {
    id: 'pages.form.step.choose.initiative.desc.list.4',
    defaultMessage: 'Proof of concept',
  },
  PRODUCT_DESC: {
    id: 'pages.form.step.choose.product.desc',
    defaultMessage: 'A digital product is a set of components, it offers some features to users and contributes to an Architecture Building Block implementation.',
  },
  PRODUCT_DESC_LIST_INTRO: {
    id: 'pages.form.step.choose.product.desc.list.intro',
    defaultMessage: 'A digital product has those characteristics:',
  },
  PRODUCT_DESC_LIST_1: {
    id: 'pages.form.step.choose.product.desc.list.1',
    defaultMessage: 'Is single deployable unit',
  },
  PRODUCT_DESC_LIST_2: {
    id: 'pages.form.step.choose.product.desc.list.2',
    defaultMessage: 'Has its own roadmap',
  },
  PRODUCT_DESC_LIST_3: {
    id: 'pages.form.step.choose.product.desc.list.3',
    defaultMessage: 'Has its own KPI',
  },
  PRODUCT_DESC_LIST_4: {
    id: 'pages.form.step.choose.product.desc.list.4',
    defaultMessage: 'Can expose some API',
  },
  PRODUCT_DESC_LIST_5: {
    id: 'pages.form.step.choose.product.desc.list.5',
    defaultMessage: 'Creates added business value',
  },
  PRODUCT_DESC_LIST_6: {
    id: 'pages.form.step.choose.product.desc.list.6',
    defaultMessage: 'Has its own profit and loss account',
  },
  INITIATIVE_CREATE: {
    id: 'pages.form.step.choose.initiave.create',
    defaultMessage: 'Create an initiative',
  },
  PRODUCT_CREATE: {
    id: 'pages.form.step.choose.product.create',
    defaultMessage: 'Create a product',
  },
});
