import { defineMessages } from 'react-intl';

import {
  PRODUCT_STAGES,
  PRODUCT_SORTING,
  PRODUCT_HOSTING_TYPES,
} from 'config/constants';

export default defineMessages({
  BU_GLOBAL: { id: 'product.bu.global', defaultMessage: 'Global product' },
  BU_LEADER: { id: 'product.bu.owner', defaultMessage: 'BU leader' },
  AUTHOR: { id: 'product.author', defaultMessage: 'Author' },
  [PRODUCT_STAGES.INI]: {
    id: 'product.stage.ini',
    defaultMessage: 'Initiative',
  },
  [PRODUCT_STAGES.TBD]: {
    id: 'product.stage.tbd',
    defaultMessage: 'To Be Developed',
  },
  [PRODUCT_STAGES.WIP]: {
    id: 'product.stage.wip',
    defaultMessage: 'Work In Progress',
  },
  [PRODUCT_STAGES.LIVE]: {
    id: 'product.stage.live',
    defaultMessage: 'Live',
  },
  [PRODUCT_STAGES.DEC]: {
    id: 'product.stage.dec',
    defaultMessage: 'Deprecated',
  },
  [PRODUCT_STAGES.DEP]: {
    id: 'product.stage.dep',
    defaultMessage: 'Decommissioned',
  },
  [PRODUCT_STAGES.ARCH]: {
    id: 'product.stage.arch',
    defaultMessage: 'Archived',
  },
  [PRODUCT_SORTING.NAME_ASC]: {
    id: 'product.sorting.name',
    defaultMessage: 'Name',
  },
  [PRODUCT_SORTING.CREATED_DATE_DESC]: {
    id: 'product.sorting.createdDate',
    defaultMessage: 'Created',
  },
  [PRODUCT_SORTING.UPDATED_DATE_DESC]: {
    id: 'product.sorting.updatedDate',
    defaultMessage: 'Last updated',
  },
  [PRODUCT_HOSTING_TYPES.OPA]: {
    id: 'product.hostingTypes.onPremiseAdeo',
    defaultMessage: 'On Premise Adeo',
  },
  [PRODUCT_HOSTING_TYPES.OPB]: {
    id: 'product.hostingTypes.onPremiseBU',
    defaultMessage: 'On Premise BU',
  },
  [PRODUCT_HOSTING_TYPES.PRC]: {
    id: 'product.hostingTypes.privateCloud',
    defaultMessage: 'Private Cloud',
  },
  [PRODUCT_HOSTING_TYPES.PUC]: {
    id: 'product.hostingTypes.publicCloud',
    defaultMessage: 'Public Cloud',
  },
  [PRODUCT_HOSTING_TYPES.SAAS]: {
    id: 'product.hostingTypes.SaaS',
    defaultMessage: 'SaaS',
  },
});
