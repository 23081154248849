import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';
import { PRODUCT_VIEW, PRODUCT_STAGES } from 'config/constants';
import { productShape } from 'shapes/product';
import { userShape } from 'shapes/user';
import { sanitize } from 'services/html';
import LinesEllipsis from 'react-lines-ellipsis';
import HtmlLinesEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import Link from 'react/generic/link/Link';
import ProductRibbonSwitch from 'react/business/products/ribbons/ProductRibbonSwitch';
import ProductRating from 'react/business/products/badges/productRating/ProductRating';
import ProductBUCount from 'react/business/products/badges/buCount/ProductBUCount';
import FollowProductButton from 'react/business/products/followButton/FollowProductButton';
import UserCard from 'react/business/user/UserCard';
import Card from 'react/generic/card/Card';
import ProductLogo from 'react/business/products/logo/ProductLogo';
import ProductStage from 'react/business/products/details/summary/stage/ProductStage';

import { FormattedMessage } from 'react-intl';
import classNames from './product-card.module.scss';
import messages from './product-card.messages';

export const DisplayTypes = {
  DEFAULT: 'default',
  COMPACT: 'compact',
  CAROUSEL: 'carousel',
};

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
const ResponsiveHtmlEllipsis = responsiveHOC()(HtmlLinesEllipsis);

class ProductCard extends React.PureComponent {
  static propTypes = {
    product: productShape.isRequired,
    user: userShape,
    displayType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    user: null,
    displayType: DisplayTypes.DEFAULT,
    className: null,
    disabled: false,
  };

  onClickFollowButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * Renders the component.
   *
   * @returns {object} - The React element.
   */
  render() {
    const {
      product,
      className,
      user,
      displayType,
      disabled,
    } = this.props;

    return (
      <Card
        className={cn(
          classNames.card,
          displayType === DisplayTypes.COMPACT && classNames.compact,
          displayType === DisplayTypes.CAROUSEL && classNames.carousel,
          className,
        )}
      >
        <ProductRibbonSwitch product={product} />

        <div className={classNames.header}>
          <Link
            isDisabled={disabled}
            to={formatRoute(ROUTES.PRODUCT.PRESENTATION, {
              productId: product.id,
              view: PRODUCT_VIEW.REVIEWS,
            })}
          >
            <ProductRating
              reviewCount={product.marks?.count}
              mark={product.marks?.average}
              className={classNames.productRating}
              inline={displayType === DisplayTypes.COMPACT}
              disabled={disabled}
            />
          </Link>

          { (displayType === DisplayTypes.DEFAULT
            && product.stage === PRODUCT_STAGES.DEP) && (
              <ProductStage stage={product.stage} />
          ) }

          <FollowProductButton
            className={classNames.button}
            productId={product.id}
            productName={product.name}
            onClick={this.onClickFollowButton}
            disabled={disabled}
          />
        </div>

        <Link
          isDisabled={disabled}
          to={formatRoute(ROUTES.PRODUCT.PRESENTATION, {
            productId: product.id,
            view: PRODUCT_VIEW.OVERVIEW,
          })}
          className={classNames.link}
        >
          { displayType === DisplayTypes.COMPACT && (
            <ProductLogo
              product={product}
              className={classNames.logo}
            />
          ) }

          <div className={classNames.nameVersion}>
            <h2 className={classNames.nameContainer}>
              <ResponsiveEllipsis
                text={product.name}
                maxLine={3}
                basedOn="words"
              />
            </h2>
          </div>

          { displayType !== DisplayTypes.COMPACT && (
            <div className={classNames.main}>
              <ProductLogo
                product={product}
                className={classNames.logo}
              />
              <div className={classNames.aside}>
                <ResponsiveHtmlEllipsis
                  unsafeHTML={product.shortDescription ? sanitize(product.shortDescription) : ''}
                  className={classNames.description}
                  maxLine={4}
                  basedOn="words"
                  ellipsisHTML={'...&nbsp;'}
                />
              </div>
            </div>
          ) }
        </Link>

        <div className={classNames.footer}>
          <ProductBUCount
            count={product.businessUnitsUsingProduct?.length}
            className={cn(
              classNames.buCount,
              !user && !(displayType === DisplayTypes.COMPACT) && classNames.noUser,
              product.isMaintainedByPlatform && classNames.firstCol,
            )}
            small={displayType === DisplayTypes.CAROUSEL}
          />

          { user && displayType === DisplayTypes.DEFAULT && (
            <UserCard
              user={user}
              className={classNames.userCard}
              projectRole="Commerce Manager"
              compact
            />
          ) }

          { !user
            && !(displayType === DisplayTypes.COMPACT || displayType === DisplayTypes.CAROUSEL)
            && product.isMaintainedByPlatform && (
              <div className={classNames.maintained}>
                <FormattedMessage
                  {...messages.MAINTAINED_BY_PLATFORM}
                />
              </div>
          )}
        </div>
      </Card>
    );
  }
}

export default ProductCard;
