import agent from 'services/http';

import { mapSearchResultsFromServer } from './searches.mapper';

/**
 * Perform an autocomplete search on the repository.
 *
 * @async
 * @param {string} title - Full title or contains.
 * @param {string} context - Context of the search.
 * @returns {object} - Result.
 */
export const searchByTitle = async (title, context) => {
  const { body } = await agent
    .get('/api/searches/titles')
    .query({
      q: title,
      context,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Perform product title existence.
 *
 * @param {string} title - Full title.
 * @returns {object} - Result.
 */
export const checkTitle = async (title) => {
  const { body } = await agent
    .get('/api/searches/productTitle')
    .query({
      q: title,
      isMatch: true,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Search a product title in the product list.
 *
 * @param {string} title - Full title.
 * @param {boolean} keepInitiatives - Remove initiatives from the list.
 * @returns {object} - Result.
 */
export const getProductTitlesStartWith = async (title, keepInitiatives) => {
  const { body } = await agent
    .get('/api/searches/productTitle')
    .query({
      q: title,
      isMatch: false,
      keepInitiatives,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Check if the title of the current product in form already exist.
 *
 * @param {string} title - Title.
 * @param {string} currentProductId - Id of the current product in form.
 * @returns {object} - Result filtered on id.
 */
export const checkIfProductTitleExists = async (title, currentProductId) => {
  const result = await checkTitle(title.trim());

  return result.filter(t => t.id !== currentProductId);
};
