import React from 'react';
import { compose, branch, renderComponent } from 'recompose';

import { PRODUCT_STAGES } from 'config/constants';
import { productShape } from 'shapes/product';

import InitiativeRibbon from './InitiativeRibbon';
import GlobalProductRibbon from './GlobalProductRibbon';
import GlobalProductTooltip from '../tooltips/GlobalProductTooltip';
import InitiativeTooltip from '../tooltips/InitiativeTooltip';

const ProductRibbonSwitch = compose(
  branch(
    props => !!props.product?.isGlobal,
    renderComponent(() => (
      <GlobalProductTooltip placement="top">
        <GlobalProductRibbon />
      </GlobalProductTooltip>
    )),
  ),

  branch(
    props => props.product?.stage === PRODUCT_STAGES.INI,
    renderComponent(() => (
      <InitiativeTooltip placement="top">
        <InitiativeRibbon />
      </InitiativeTooltip>
    )),
  ),
)(() => null);

ProductRibbonSwitch.displayName = 'ProductRibbonSwitch';

ProductRibbonSwitch.propTypes = {
  product: productShape,
};

ProductRibbonSwitch.defaultProps = {
  product: null,
};

export default ProductRibbonSwitch;
