import { compose, lifecycle } from 'recompose';
import { uniq } from 'lodash';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/reviews/constants';
import { getLatestReviews } from 'redux/reviews/actions';
import { selectLatestReviewsList } from 'redux/reviews/selectors';

import { getProduct } from 'redux/products/actions';

export default compose(
  connect(
    state => ({
      reviews: selectLatestReviewsList(state),
    }),
    {
      getLatestReviews,
      getProduct,
    },
  ),

  lifecycle({
    /**
     * Load the 3 latest reviews on mount.
     */
    async componentDidMount() {
      const action = await this.props.getLatestReviews();

      if (action.type === ACTIONS.GET_LATEST_REVIEWS_SUCCESS) {
        // Get product ids
        const ids = uniq(this.props.reviews?.map(review => review.product) || []);
        await Promise.all(ids.map(id => this.props.getProduct(id)));
      }
    },
  }),
);
