import React, { memo } from 'react';

import { Tooltip } from 'antd';
import messages from 'react/business/products/product.messages';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

const GlobalProductTooltip = props => (
  <FormattedMessageChildren {...messages.BU_GLOBAL}>
    { message => <Tooltip title={message} {...props} /> }
  </FormattedMessageChildren>
);

GlobalProductTooltip.displayName = 'GlobalProductTooltip';

export default memo(GlobalProductTooltip);
