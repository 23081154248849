import React from 'react';
import { Menu } from 'antd';

import { compose } from 'recompose';

import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from './helpMenu.module.scss';
import messages from './helpMenu.messages';
import Asset, { ICON_MAP } from '../../../generic/asset/Asset';

const { Item, ItemGroup } = Menu;

const enhancer = compose(
);

const HelpMenu = ({ onClick }) => (
  <Menu
    mode="vertical"
    onClick={onClick}
    className={classNames.userMenu}
  >
    <ItemGroup>
      <div className={classNames.helpMenuTitle}>
        <FormattedMessage {...messages.SUPPORT_AND_HELP} />
      </div>
      <Item key="item-1">
        <div className={classNames.iconAndTitle}>
          <div className={classNames.bookIcon}>
            <Asset
              name={ICON_MAP.book_simple}
            />
          </div>
          <a href="https://sites.google.com/adeo.com/tangram/features?authuser=0">
            <FormattedMessage {...messages.DOCUMENTATION} />
          </a>
        </div>
      </Item>
      <Item key="item-2">
        <div className={classNames.iconAndTitle}>
          <div className={classNames.slackIcon}>
            <Asset
              name={ICON_MAP.slack}
            />
          </div>
          <a href="https://sites.google.com/adeo.com/tangram/support?authuser=0">
            <FormattedMessage {...messages.GET_HELP} />
          </a>
        </div>
      </Item>
      <Item key="item-3">
        <div className={classNames.iconAndTitle}>
          <div className={classNames.externalLink}>
            <Asset
              name={ICON_MAP.external_link}
            />
          </div>
          <a href="https://sites.google.com/adeo.com/tangram/data/data-access?authuser=0 ">
            <FormattedMessage {...messages.DATA_ACCESS} />
          </a>
        </div>
      </Item>
    </ItemGroup>

  </Menu>
);

HelpMenu.propTypes = {
  onClick: PropTypes.func,
};

HelpMenu.defaultProps = {
  onClick: noop,
};

export default enhancer(HelpMenu);
