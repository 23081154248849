import React, { memo } from 'react';
import { compose } from 'recompose';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import CornerRibbon from 'react/generic/ribbon/corner-ribbon/CornerRibbon';

import classNames from './initiative-ribbon.module.scss';

const enhancer = compose(
  memo,
);

const InitiativeRibbon = props => (
  <CornerRibbon
    {...props}
    contentClassName={classNames.content}
    overrideClassName={classNames.ribbon}
    overrideClassNames={classNames}
  >
    <Asset
      name={ICON_MAP.box}
      className={classNames.icon}
    />
  </CornerRibbon>
);

InitiativeRibbon.displayName = 'InitiativeRibbon';

export default enhancer(InitiativeRibbon);
