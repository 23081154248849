export const PRODUCT_FORM_STEPS = {
  PRESENTATION: 'presentation',
  FEATURES: 'features',
  TEAM: 'team',
  BUSINESS_UNITS: 'businessUnits',
  LINKS: 'links',
  BUSINESS_ACTIVITIES: 'businessActivities',
  ARCHITECTURE_BUILDING_BLOCKS: 'architectureBuildingBlocks',
  SUBSETS: 'subsets',
  VALIDATION: 'validation',
};

// Can't do Object.values(STEPS_RENDERER) because order of keys is not guaranteed.
export const makeStepsToDisplay = () => [
  PRODUCT_FORM_STEPS.PRESENTATION,
  PRODUCT_FORM_STEPS.FEATURES,
  PRODUCT_FORM_STEPS.TEAM,
  PRODUCT_FORM_STEPS.BUSINESS_UNITS,
  PRODUCT_FORM_STEPS.LINKS,
  PRODUCT_FORM_STEPS.BUSINESS_ACTIVITIES,
  PRODUCT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS,
  PRODUCT_FORM_STEPS.VALIDATION,
].filter(Boolean);
