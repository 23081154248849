import { noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from 'recompose';
import memoize from 'memoize-one';

import swal from 'sweetalert';
import { valueShape } from './select.shapes';

const MODULE_FIELD = 'module';

// Encapsulate react-select value management.
// Use option value as value (instead of option object).
// onChange prop gives the option value (instead of option object).
export default WrappedComponent => class withSelectValueSelector extends Component {
  static displayName = wrapDisplayName(WrappedComponent, 'withSelectValueSelector');

  static propTypes = {
    /** Value of the current option. */
    // eslint-disable-next-line react/require-default-props
    value: valueShape,
    /** Options of the select. */
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
    })),
    /** Called with the value of the option selected. */
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
    name: PropTypes.string,
    fieldType: PropTypes.string,
  };

  static defaultProps = {
    options: [],
    onChange: noop,
    isMulti: false,
    name: '',
    fieldType: '',
  };

  /**
   * Memoize the option matching the current value.
   *
   * @param {string|number} value - Current value.
   * @param {object[]} [options] - Current options.
   * @returns {object} Current option, or null.
   */
  getOption = memoize((value, options, isMulti) => (
    isMulti ?
      value?.map(val => options?.find(opt => opt.value === val)).filter(Boolean)
      : options?.find(opt => opt.value === value) || null
  ));

  /**
   * Call the onChange prop with the selected option value.
   *
   * @param {object} opt - Selected option.
   * @returns {any} Props onChange return value.
   */
  // eslint-disable-next-line consistent-return
  onChange = (opt) => {
    if (this.props.isMulti) {
      return this.props.onChange(opt?.map(op => op.value) || []);
    }
    if (opt?.value === 'ARCH') {
      this.openArchivedPopup(
        opt,
        this.props.value,
        this.fieldType,
      );
    } else {
      if (opt?.value || opt?.value === 0) {
        return this.props.onChange(opt?.value);
      }
      return this.props.onChange(null);
    }
  };

  getOptions = memoize((value, options, name) => {
    if (name === 'stage') {
      return ['INI', 'DEP'].includes(value) ? options : options?.filter(opt => opt.value !== 'ARCH');
    }
    return options;
  });

  openArchivedPopup = (opt, previousValue) => {
    const element = document.createElement('div');
    element.innerHTML
    = '<b>Are you sure?</b>';

    const title = this.props.fieldType === MODULE_FIELD ?
      'You are going to archive a module. This one will no longer be visible in your product overview.'
      : 'You are going to archive your digital product. The product will no longer be visible in Tangram, or from your space "My Products".';

    // FIXME i18n this
    swal({
      title,
      content: element,
      buttons: ['Yes', 'No'],
    }).then((result) => {
      if (result) {
        this.props.onChange(previousValue);
      } else {
        this.props.onChange(opt?.value || null);
      }
    });
  };

  /** @returns {object} JSX. */
  render = () => (
    <WrappedComponent
      {...this.props}
      options={this.getOptions(this.props.value, this.props.options, this.props.name)}
      value={this.getOption(this.props.value, this.props.options, this.props.isMulti)}
      onChange={this.onChange}
    />
  )
};
