/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import activity from 'style/imgs/assets/activity.svg';
import architecture_off from 'style/imgs/assets/architecture_off.svg';
import architecture_on from 'style/imgs/assets/architecture_on.svg';
import book_simple from 'style/imgs/assets/book_simple.svg';
import book from 'style/imgs/assets/book.svg';
import box from 'style/imgs/assets/box.svg';
import build from 'style/imgs/assets/build.svg';
import check_circle from 'style/imgs/assets/check_circle.svg';
import check_icon from 'style/imgs/assets/check_icon.svg';
import chevron from 'style/imgs/assets/chevron.svg';
import chevron_large from 'style/imgs/assets/chevron_large.svg';
import cloud_upload_arrow from 'style/imgs/assets/cloud-upload-arrow.svg';
import comment from 'style/imgs/assets/comment.svg';
import contact from 'style/imgs/assets/contact.svg';
import display_grid from 'style/imgs/assets/display_grid.svg';
import display_grid_compact from 'style/imgs/assets/display_grid_compact.svg';
import display_list from 'style/imgs/assets/display_list.svg';
import draft from 'style/imgs/assets/draft.svg';
import edit from 'style/imgs/assets/edit.svg';
import edit_square from 'style/imgs/assets/edit_square.svg';
import external_link from 'style/imgs/assets/external_link.svg';
import eye from 'style/imgs/assets/eye.svg';
import eye_off from 'style/imgs/assets/eye_off.svg';
import github from 'style/imgs/assets/github.svg';
import idea from 'style/imgs/assets/idea.svg';
import info from 'style/imgs/assets/info.svg';
import innersource_off from 'style/imgs/assets/innersource_off.svg';
import innersource_on from 'style/imgs/assets/innersource_on.svg';
import layer from 'style/imgs/assets/layer.svg';
import leave from 'style/imgs/assets/leave.svg';
import loupe from 'style/imgs/assets/loupe.svg';
import message_circle from 'style/imgs/assets/message_circle.svg';
import minus from 'style/imgs/assets/minus.svg';
import no_screenshot from 'style/imgs/assets/no_screenshot.svg';
import phone from 'style/imgs/assets/phone.svg';
import plus from 'style/imgs/assets/plus.svg';
import plus_circle from 'style/imgs/assets/plus_circle.svg';
import quote_close from 'style/imgs/assets/quote_close.svg';
import quote_open from 'style/imgs/assets/quote_open.svg';
import reset_icon from 'style/imgs/assets/reset_icon.svg';
import roadmap from 'style/imgs/assets/roadmap.svg';
import save from 'style/imgs/assets/save.svg';
import security_off from 'style/imgs/assets/security_off.svg';
import security_on from 'style/imgs/assets/security_on.svg';
import send from 'style/imgs/assets/send.svg';
import settings from 'style/imgs/assets/settings.svg';
import share_full from 'style/imgs/assets/share_full.svg';
import solution from 'style/imgs/assets/solution.svg';
import star_outline from 'style/imgs/assets/star_outline.svg';
import thumb_down from 'style/imgs/assets/thumb_down.svg';
import thumb_up from 'style/imgs/assets/thumb_up.svg';
import translate from 'style/imgs/assets/translate.svg';
import trash from 'style/imgs/assets/trash.svg';
import trello from 'style/imgs/assets/trello.svg';
import user_menu from 'style/imgs/assets/user_menu.svg';
import x_circle from 'style/imgs/assets/x_circle.svg';
import x from 'style/imgs/assets/x.svg';
import world from 'style/imgs/assets/global.svg';
import help from 'style/imgs/assets/help.svg';
import slack from 'style/imgs/assets/slack.svg';
import datasets from 'style/imgs/assets/datasets.svg';

import classNames from './asset.module.scss';

export const ICON_FILES = {
  activity,
  architecture_off,
  architecture_on,
  book_simple,
  book,
  box,
  build,
  check_circle,
  check_icon,
  chevron,
  chevron_large,
  cloud_upload_arrow,
  comment,
  contact,
  display_grid,
  display_grid_compact,
  display_list,
  draft,
  edit,
  edit_square,
  external_link,
  eye,
  eye_off,
  github,
  idea,
  info,
  innersource_off,
  innersource_on,
  layer,
  leave,
  loupe,
  message_circle,
  minus,
  no_screenshot,
  phone,
  plus,
  plus_circle,
  quote_close,
  quote_open,
  reset_icon,
  roadmap,
  save,
  security_off,
  security_on,
  send,
  settings,
  share_full,
  solution,
  star_outline,
  thumb_down,
  thumb_up,
  translate,
  trash,
  trello,
  user_menu,
  x_circle,
  x,
  world,
  help,
  slack,
  datasets,
};

export const ICON_MAP = Object.keys(ICON_FILES).reduce(
  (iconMap, name) => {
    iconMap[name] = name;
    return iconMap;
  },
  {},
);

export default class Asset extends PureComponent {
  static propTypes = {
    name: PropTypes.oneOf(Object.keys(ICON_FILES)).isRequired,
  };

  /** @returns {object} JSX. */
  render() {
    const {
      name,
      ...props
    } = this.props;

    props.className = cn(props.className, classNames.asset);

    const element = ICON_FILES[name];

    if (!element) {
      return null;
    }

    return React.createElement(element, props);
  }
}
