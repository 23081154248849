import React from 'react';
import { keyBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { PRODUCT_STAGES } from 'config/constants';
import productMessages from 'react/business/products/product.messages';

export const stagesOptions = [
  { value: PRODUCT_STAGES.INI, label: <FormattedMessage {...productMessages.INI} /> },
  { value: PRODUCT_STAGES.TBD, label: <FormattedMessage {...productMessages.TBD} /> },
  { value: PRODUCT_STAGES.WIP, label: <FormattedMessage {...productMessages.WIP} /> },
  { value: PRODUCT_STAGES.LIVE, label: <FormattedMessage {...productMessages.LIVE} /> },
  { value: PRODUCT_STAGES.DEC, label: <FormattedMessage {...productMessages.DEC} /> },
  { value: PRODUCT_STAGES.DEP, label: <FormattedMessage {...productMessages.DEP} /> },
  { value: PRODUCT_STAGES.ARCH, label: <FormattedMessage {...productMessages.ARCH} /> },
];

export const stagesInfoLink = 'https://adeo.collibra.com/asset/9be3cc5e-bab9-4274-a27b-60a0d326df3e';

export const stagesOptionsMap = keyBy(stagesOptions, 'value');
