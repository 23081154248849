import { isEmpty, noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Link from 'react/generic/link/Link';

import { BUSINESS_OBJECTS } from 'config/constants';
import { itemShape } from './autocompleteResultsSection.shapes';
import classNames from '../../autocompleteResults.module.scss';

const enhancer = compose(
  memo,
);

const AutocompleteResultsSection = ({
  items,
  title,
  actionLabel,
  type,
  onResultClick,
}) => (!isEmpty(items) ? (
  <section className={classNames.section}>
    {title && <h2 className={classNames.sectionTitle}>{title}</h2>}

    <div className={classNames.sectionContent}>
      <ul className={classNames.sectionList}>
        {items.map(item => (
          <li
            key={item.id}
            className={classNames.sectionListItem}
          >
            <Link
              to={item.href}
              onClick={() => {
                onResultClick(item);
              }}
            >

              <div className={classNames.sectionListItemName}>
                {type !== BUSINESS_OBJECTS.PERSONS ?
                  item.name : `${(`${item.firstName} ${item.lastName}`).toUpperCase()} (${item.externalId})`}
              </div>

              {actionLabel
                  && (
                    <div className={classNames.sectionListItemAction}>
                      {actionLabel}
                    </div>
                  )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </section>
) : null);

AutocompleteResultsSection.displayName = 'AutocompleteResultsSection';

AutocompleteResultsSection.propTypes = {
  items: PropTypes.arrayOf(itemShape),
  title: PropTypes.node,
  actionLabel: PropTypes.node,
  type: PropTypes.string,
  onResultClick: PropTypes.func,
};

AutocompleteResultsSection.defaultProps = {
  items: [],
  title: null,
  actionLabel: null,
  type: '',
  onResultClick: noop,
};

export default enhancer(AutocompleteResultsSection);
