/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { getTipsAndNews } from 'redux/tipsAndNews/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectTipsAndNews } from 'redux/tipsAndNews/selector';
import classNames from './tipsAndNews.module.scss';

const TipsAndNews = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectTipsAndNews);
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Dispatch de votre action au chargement du composant
    dispatch(getTipsAndNews());
    setShow(data.length > 0);
  }, [data.length, dispatch]);

  return show ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames.tickerWrap}
      onClick={() => {
        setShow(false);
      }}
      style={{ '--number-of-items': data.length }}
    >
      <div className={classNames.ticker}>
        {data?.map(item => (
          <div className={classNames.ticker__item} key={item._id}>
            <span>{item.type}</span>{' - '}<span dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        ))}
      </div>
    </div>
  )
    : <></>;
};

export default TipsAndNews;
